<template>
    <div>
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <div class='serviceContent'>
            <div class="clearfix serviceTop">
                <div class="fl">
                    <img :src="list.companyPic" alt="">
                </div>
                <div class="fl">
                    <div class='companyName'>{{list.companyName}}</div>
                    <div class='flex orderCount'>
                        <div class='flex_length orderItem'>
                            <p>{{list.orderCount}}</p>
                            <div>预约次数</div>
                        </div>
                        <div class='flex_length orderItem'>
                            <p>{{list.viewCount}}</p>
                            <div>浏览次数</div>
                        </div>
                        <div class='flex_length orderItem'>
                            <p>{{list.servePrice}}</p>
                            <div>价格服务</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='detaile'>
                <div class='first'>服务详情</div>
                <div class='second'>{{list.serveDetail}}</div>
            </div>
            <div class='detaile'>
                <div class='first'>机构介绍</div>
                <div class='second'>{{list.serveContent}}</div>
            </div>
            <div class='detaile'>
                <div class='first'>服务评价</div>
                <div>
                    <div class='flex commentTop'>
                        <div class='avgScore'>
                            <div class='score'>{{ (list.avgScore && list.avgScore.length >= 5) ? list.avgScore.substring(0, 5) : list.avgScore}}</div>
                            <div>评分</div>
                        </div>
                        <div  class='flex_length' v-if='commentsList.serveScorePercent'>
                            <div class='flex'>
                                <span>好评</span>
                                <el-progress class='comments' :stroke-width='9' :percentage="Number(commentsList.serveScorePercent.perfectPercent)"></el-progress>
                            </div>
                            <div class='flex goodPercent'>
                                <span>中评</span>
                                <el-progress class='comments'  :stroke-width='9' :percentage="Number(commentsList.serveScorePercent.goodPercent)"></el-progress>
                            </div>
                            <div class='flex'>
                                <span>差评</span>
                                <el-progress class='comments' :stroke-width='9' :percentage="Number(commentsList.serveScorePercent.diffPercent)"></el-progress>
                            </div>
                        </div>
                    </div>
                    <!-- 评价列表 -->
                    <div>
                        <el-collapse @change='handelChange' v-model="activeNames">
                            <el-collapse-item name='1'>
                                <template slot="title">
                                    <div class='clearfix commentsTitle'>
                                        <div class='fl'>全部评价</div>
                                        <div class='fr'>
                                            <span> 累计{{commentsList.count}}条评价</span>
                                            <span class='last'>{{icon?'展开':'收起'}}</span><i class="header-icon" :class='icon?"el-icon-arrow-down":"el-icon-arrow-up"'></i>
                                        </div>
                                    </div>
                                    
                                </template>
                                <div class='commentList'>
                                    <ul class='clearfix' v-for='(item, index) in commentsList.list' :key='index'>
                                        <li class='fl userInfo flex'>
                                            <img :src="item.userImg" alt="">
                                            <span v-if='item.servePhone'>{{item.servePhone.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")}}</span>
                                        </li>
                                        <li class='fl'>
                                            <div>
                                                <el-rate
                                                    v-model="item.serveScore"
                                                    disabled
                                                    text-color="#ff9900"
                                                    score-template="{value}">
                                                </el-rate>
                                            </div>
                                            <div class='commentContent'>
                                                {{item.commentContent}}
                                            </div>
                                            <div>{{item.applyDate.substring(0,19)}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </div>
            <div class='detaile applicationServices'>
                <button @click='onGoToQrCode'>申请服务</button>
            </div>
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import Vue from 'vue';
import { Loading, Rate, Collapse, CollapseItem } from 'element-ui';
Vue.use(Rate);
Vue.use(Loading);
Vue.use(Collapse);
Vue.use(CollapseItem);
let loadingInstance;
export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
            breadList: [{
                name: '南繁服务'
            }, {
                name: '',
                path: ''
            }, {
                name: '详情'
            }],
            list: '',
            commentsList: '',
            icon: false,
            activeNames: '1'
        };
    },
    async created() {
        loadingInstance = Loading.service({ fullscreen: true });
        if (this.$route.query && this.$route.query.title) {
            document.title = this.$route.query.title;
            this.$set(this.breadList[1], 'name', this.$route.query.title);
            this.$set(this.breadList[2], 'name', this.$route.query.title + '详情');
            this.$set(this.breadList[1], 'path', '/publicService?type='+ this.$route.query.type);
           await this.getDetaile(this.$route.query.id);
           await this.getCommitList(this.$route.query.id);
        }
    },
    methods: {
        // 详情
        async getDetaile(id) {
            const api = this.$fetchApi.innovationServeDetail.api + id;
            const data = await this.$fetchData.fetchPost('', api, "json");
            if (data.code === '200' && data.result) {
                this.list = data.result;
            }
        },
        // 评价列表
        async getCommitList(id) {
            const api = this.$fetchApi.innovationServeList.api + `/${id}`;
            const data = await this.$fetchData.fetchPost('', api, "json");
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
            if (data.code === '200' && data.result) {
                this.commentsList = data.result;
                this.commentsList.list.forEach((item, index) => {
                    this.$set( this.commentsList.list[index], 'serveScore', Number(item.serveScore));
                });
            }
        },
        handelChange() {
            this.icon = !this.icon;
        },
        onGoToQrCode() {
            this.$router.push({
                path: '/downLoad'
            });
        }
    }
};
</script>
<style lang="less" scoped>
.serviceContent{
    width:1186px;
    margin:20px auto 40px;
    .companyName{
        width: 910px;
        height: 62px;
        background-color: #fafafa;
        border-radius: 0px 4px 4px 0px;
        border: solid 1px #f5f5f5;
        line-height: 62px;
        text-align: center;
        font-size: 24px;
        color: #333;
    }
    .orderCount{
        text-align: center;
        margin-top:27px;
        .orderItem{
            p{
                color: #448afc;
                font-size: 36px;
            }
            div{
                color: #5c5c5c;
                font-size:16px;
            }
        }
    }
    .applicationServices{
        text-align: center;
        button{
            width: 180px;
            height: 48px;
            background-color: #265ae1;
            border-radius: 4px;
            font-size: 18px;
            color: #ffffff;
            text-align: center;
            line-height: 48px;
        }
    }
    .detaile{
        font-size:16px;
        color: #333;
        width:1180px;
        margin:40px auto 0;
        .first{
            font-size:18px;
            border-bottom:1px solid #d9d9d9;
            padding-bottom:18px;
            line-height: 18px;
        }
        .first::before{
            border-left: 6px solid #265ae1;
            height:20px;
            content: '';
            margin-right:10px;
        }
        .second{
            padding-top:20px;
            line-height: 30px;
            text-indent: 2em;
            width:1178px;
            margin:0 auto;
        }
    }
    /deep/ .el-collapse-item__arrow {
        display: none;
    }
}
.serviceTop{
    img{
        width: 270px;
        height: 169px;
    }
}
.avgScore{
    width:177px;
    text-align: center;
    color: #5c5c5c;
    font-size:14px;
    .score{
        color: #448afc;
        font-size: 45px;
    }
}
    /deep/ .el-progress{
    width:388px;
}
/deep/ .el-progress-bar__outer{
    margin:0 16px;
}
.commentTop{
    margin-top:24px;
    .goodPercent{
        margin:19px 0;
    }
}
.commentsTitle{
    background-color: #fafafa;
    border: solid 1px #f5f5f5;
    color: #5c5c5c;
    font-size:14px;
    width:100%;
    padding: 0 16px 0 20px;
    .last{
        margin: 0 9px 0 54px;
        color: #265ae1;
    }
}


.commentList {
    color: #5c5c5c;
    font-size:14px;
    ul{
        margin:18px 0 0 14px;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 24px;
    }
    .userInfo{
        margin-right:58px;
        img{
            width:30px;
            height:30px;
            border-radius: 100%;
            margin-right:10px;
        }
    }
    .commentContent{
        margin: 20px 0;
        width:950px;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
</style>